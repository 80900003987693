


























































































































































































import Vue, { PropType } from "vue";
import { Jobs } from "@/interfaces/data_objects/jobs";
import { EditJobForm } from "@/interfaces/recruiter/jobs/edit_job_details";
import { minLength, required } from "vuelidate/lib/validators";
import { TranslateResult } from "vue-i18n";
import { mapActions, mapGetters } from "vuex";
import {
  COMMON_LOADING,
  GET_UNIQUE_SECTORS,
  SKILL_SEARCH
} from "@/store/modules/common/constants";
import { Skills } from "@/interfaces/data_objects/skills";
import { UpskillCourses } from "@/interfaces/data_objects/upskill_courses";
import { JobTypeOptions } from "@/interfaces/recruiter/jobs/post_job/add_job_details";

export default Vue.extend({
  name: "EditJobDetails",
  data() {
    return {
      search_skill_input: "" as string,
      searched_skills: [] as string[],
      object_format_skills_list: false as boolean,
      job_types: [
        {
          text: this.$t("recruiter.post-job.manual.job-type-op1"),
          value: "Full Time"
        },
        {
          text: this.$t("recruiter.post-job.manual.job-type-op2"),
          value: "Part Time"
        }
      ] as JobTypeOptions[],
      edit_job_form: {
        experience: "",
        job_description: "",
        sector: "",
        job_title: "",
        employment_type: "",
        skills_list: []
      } as EditJobForm,
      answer_editor_options: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link"]
          ]
        },
        placeholder: "Type answer here",
        table: [] // Answer editor options
      },
      is_job_description_edited: true,
      is_touched: false
    };
  },
  async created() {
    if (typeof this.job.skills_list === "object")
      this.object_format_skills_list = true;
    this.edit_job_form.job_description = this.job.job_description ?? "";
    this.edit_job_form.experience =
      this.job.experience && this.job.experience !== "null"
        ? this.job.experience
        : "";
    this.edit_job_form.sector = this.job.sector ?? "";
    this.edit_job_form.job_title = this.job.job_title;
    this.edit_job_form.employment_type = this.job.employment_type ?? "";
    // If skills are array of object
    if (this.object_format_skills_list) {
      const skills = eval(this.job.skills_list ?? "") ?? [];
      this.edit_job_form.skills_list = [
        ...skills.map((skill: UpskillCourses.CourseSkill) => skill.skill)
      ];
      this.searched_skills = this.edit_job_form.skills_list;
    } else {
      this.edit_job_form.skills_list = eval(this.job.skills_list ?? "") ?? [];
      this.searched_skills = this.edit_job_form.skills_list;
    }

    if (this.unique_sectors.length <= 0) await this.fetch_sectors();
  },
  watch: {
    ["edit_job_form.job_description"](val: string) {
      this.is_touched = true;
      if (val.length >= 0 && val.length <= 70)
        this.is_job_description_edited = false;
      else this.is_job_description_edited = true;
    }
  },
  props: {
    job: {
      type: Object as PropType<Jobs>,
      required: true
    },
    update_loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("common", {
      unique_sectors: GET_UNIQUE_SECTORS,
      common_loading: COMMON_LOADING
    }),
    job_title_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.edit_job_form.job_title?.$dirty) return errors;
      !this.$v.edit_job_form.job_title?.required &&
        errors.push(this.$t("errors.required-error"));
      return errors;
    },
    employment_type_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.edit_job_form.employment_type?.$dirty) return errors;
      !this.$v.edit_job_form.employment_type?.required &&
        errors.push(this.$t("errors.required-error"));
      return errors;
    },
    experience_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.edit_job_form.experience?.$dirty) return errors;
      // !this.$v.edit_job_form.experience?.required &&
      //   errors.push(this.$t("errors.required-error"));
      return errors;
    },
    skills_list_errors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.edit_job_form.skills_list?.$dirty) return errors;
      !this.$v.edit_job_form.skills_list?.required &&
        errors.push(this.$t("errors.required-error"));
      !this.$v.edit_job_form.skills_list?.min &&
        errors.push(this.$t("errors.required-error"));
      return errors;
    }
  },
  methods: {
    ...mapActions("common", {
      fetch_sectors: GET_UNIQUE_SECTORS,
      skill_search: SKILL_SEARCH
    }),
    /**
     * Function to submit form
     */
    edit_job_form_submit() {
      this.$v.$touch();
      // If form is valid
      if (!this.$v.$invalid) {
        const final_data = { ...this.job, ...this.edit_job_form };
        this.$emit("update_job", final_data);
      }
    },
    /**
     * Function to fetch skills from database
     */
    async fetch_skills() {
      // If search_skill_input is null or length < 3 => return => else get skills from backend
      if (
        !this.search_skill_input ||
        (this.search_skill_input && this.search_skill_input.length <= 2)
      )
        return;
      // Fetching skills from backend
      const obj = {
        skill_name: this.search_skill_input.toLowerCase(),
        page: 0,
        limit_per_page: 35
      };
      const results = await this.skill_search(obj);
      // If skills fetched successfully
      if (results.length > 0)
        // Append previously selected & new searched skills
        this.searched_skills = [
          ...this.edit_job_form.skills_list,
          ...results.map((skill: Skills) => skill.name)
        ];
    },
    /**
     * Function to remove selected skill => after pressing close icon
     * @param index
     */
    remove_selected_skill(index: number) {
      this.searched_skills.splice(index, 1);
      this.edit_job_form.skills_list.splice(index, 1);
    }
  },
  validations() {
    return {
      edit_job_form: {
        job_title: {
          required
        },
        job_description: {
          required,
          min: minLength(70)
        },
        sector: {
          required
        },
        employment_type: {
          required
        },
        skills_list: {
          required,
          min: minLength(1)
        },
        experience: {
          // required
        }
      }
    };
  }
});
