import { clean_string } from "@/utils/global";

/**
 * Return icon based on job title
 * @param job_title: string
 * @return icon path <string>
 */
export function get_job_icon(job_title: string): string {
  const title = clean_string(job_title);
  if (title.includes("software") || title.includes("engineer"))
    return require("@/assets/icons/bold/simcard.svg");
  else if (title.includes("sqa"))
    return require("@/assets/icons/bold/monitor-mobile.svg");
  else if (
    title.includes("ux") ||
    title.includes("ui") ||
    title.includes("designer")
  )
    return require("@/assets/icons/bold/pen-tool-2.svg");
  else if (title.includes("stack") || title.includes("developer"))
    return require("@/assets/icons/bold/layer.svg");
  else return require("@/assets/icons/bold/simcard.svg");
}
