




































































































import Vue from "vue";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  COMMON_LOADING,
  FETCH_SKILLS_GROUP,
  GET_JOB_BY_ID,
  JOB_SEARCH
} from "@/store/modules/common/constants";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import ViewStandardisedJobCard from "@/components/recruiter/jobs/ViewStandardisedJobCard.vue";
import ViewJob from "@/components/recruiter/jobs/job_listing/ViewJob.vue";
import { Jobs } from "@/interfaces/data_objects/jobs";
import EditJobDetails from "@/components/recruiter/jobs/EditJobDetails.vue";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import {
  EDITED_STANDARDISED_JOB,
  FETCH_MATCHING_CANDIDATES,
  HOLD_STANDARDISED_JOB,
  POST_JOB,
  PUSH_TOP_JOB_WITH_CANDIDATES,
  RECRUITER_LOADING,
  UPDATE_TOP_MATCHING_CANDIDATES
} from "@/store/modules/recruiter/constants";
import router from "@/router";
import RootLoader from "@/components/shared/RootLoader.vue";
import { JobData, SkillList } from "@/store/modules/common/interfaces";
export default Vue.extend({
  name: "ViewStandardisedJobProfiles",
  data() {
    return {
      loading: false,
      jobs: [],
      view_standardised_job: false,
      edit_standardised_job: false,
      standardised_job: null as null | Jobs,
      show_industry: false
    };
  },
  components: {
    RootLoader,
    EditJobDetails,
    ViewJob,
    ViewStandardisedJobCard,
    DataNotFound,
    GoBackHeader
  },
  // Validating route param job_title exists on router enter
  beforeRouteEnter(to, from, next) {
    const title = to.params.job_title;
    if (!title) next(false);
    else next();
  },
  computed: {
    ...mapGetters("common", {
      common_loading: COMMON_LOADING
    }),
    ...mapGetters("recruiter", {
      recruiter_loading: RECRUITER_LOADING
    })
  },
  async created() {
    const job_title = this.$route.params.job_title;
    const obj = {
      limit_per_page: 35,
      job_title: job_title,
      is_standardize: true
    };
    this.jobs = await this.job_search(obj);
    this.show_industry = this.jobs.length > 1;
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("common", {
      job_search: JOB_SEARCH,
      fetch_skill_group: FETCH_SKILLS_GROUP,
      get_job_by_id: GET_JOB_BY_ID
    }),
    ...mapActions("recruiter", {
      post_job: POST_JOB,
      fetch_matching_candidates: FETCH_MATCHING_CANDIDATES
    }),
    ...mapMutations("recruiter", {
      push_top_job_with_candidates: PUSH_TOP_JOB_WITH_CANDIDATES,
      update_top_matching_candidates: UPDATE_TOP_MATCHING_CANDIDATES,
      hold_standardised_job: HOLD_STANDARDISED_JOB,
      set_edited_standardised_job: EDITED_STANDARDISED_JOB
    }),
    async update_job(job: Jobs) {
      this.loading = true;
      this.edit_standardised_job = false;
      this.standardised_job = job;
      // Parsing skills
      if (job.skills_list) {
        // Fetching skills with group
        const skills_with_group = await this.fetch_skill_group(job.skills_list);
        if (skills_with_group)
          this.standardised_job.skills_list = skills_with_group;
        else {
          this.root_error(this.$t("errors.internal"));
          this.reset_view_state();
        }
      }
      this.loading = false;
    },
    async set_view_standardised_job_payload(job: Jobs) {
      this.hold_standardised_job(null);
      this.set_edited_standardised_job(false);
      await this.$router.push(`/recruiter/jobs/post-job/${job.jid}/view`);
      // this.standardised_job = job;
      // this.view_standardised_job = true;
    },
    set_edit_standardised_job_payload(job: Jobs) {
      this.standardised_job = job;
      this.edit_standardised_job = true;
    },
    reset_view_state() {
      this.edit_standardised_job = false;
      this.view_standardised_job = false;
      this.standardised_job = null;
    },
    reset_edit_standardised_job_mode() {
      this.edit_standardised_job = false;
    },
    async post_new_job() {
      if (this.standardised_job) {
        const skills: SkillList[] =
          this.standardised_job &&
          Array.isArray(this.standardised_job.skills_list)
            ? this.standardised_job.skills_list
            : [];

        let skills_list = [];
        for (let skill of skills) {
          skills_list.push(skill.skill);
        }

        // Payload for post job
        const payload: JobData = {
          job_title: this.standardised_job.job_title,
          job_description: this.standardised_job.job_description,
          job_industry: this.standardised_job.sector,
          job_type: this.standardised_job.employment_type
            ? this.standardised_job.employment_type
            : "",
          job_experience: this.standardised_job.experience,
          skills_list: JSON.stringify(skills_list),
          is_standardize: true
        };
        const form_data = new FormData();
        for (let item in payload) form_data.append(item, payload[item]);
        form_data.append("post_type", "text");
        // Post job
        const response = await this.post_job(form_data);
        // If job posted successfully
        if (response) {
          this.root_notification(this.$t("success-messages.job-post-success"));
          // Fetching job from  DB
          await this.fetch_job_and_update_top_jobs(
            response.standardized_data.jid
          );
        } else {
          this.root_error(this.$t("errors.job-post-failed"));
        }
      }
    },
    /**
     * Function to fetch job from DB and updating top job data set
     */
    async fetch_job_and_update_top_jobs(job_id: number) {
      const job = await this.get_job_by_id(job_id);
      const obj = {
        ...job,
        matching_candidates: [],
        candidates_loading: true
      };
      // Update top_posted_jobs data set
      this.push_top_job_with_candidates(obj);
      await router.push("/recruiter/jobs"); // Navigate to job listing page
      // Fetching matching candidates
      const candidates_response = await this.fetch_matching_candidates({
        job_id: job_id,
        count: 15
      });
      // If candidates fetched successfully
      // Update top job data set object
      if (candidates_response) {
        this.update_top_matching_candidates({
          candidates: candidates_response,
          job_id: job_id
        });
      }
    }
  }
});
