var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-card',{staticClass:"\n      base-card\n      standardised-job-card\n      d-flex\n      flex-column\n      align-center\n      justify-space-between\n    ",attrs:{"link":""},on:{"click":function($event){return _vm.$emit('view_job', _vm.job)}}},[_c('div',{staticClass:"text-end standardised-job-card-header"},[_c('v-btn',{staticClass:"favorite-icon",attrs:{"icon":"","color":"danger"},on:{"click":function($event){$event.preventDefault();}}},[_c('v-icon',[_vm._v("mdi-cards-heart-outline")])],1)],1),_c('div',{staticClass:"align-center card-body d-flex flex-column justify-center"},[_c('v-avatar',{staticClass:"job-icon",attrs:{"width":"80","height":"80","color":_vm.color_code}},[_c('inline-svg',{attrs:{"src":_vm.get_job_icon(_vm.job.job_title),"transformSource":_vm.transform_job_icon}})],1),_c('p',{staticClass:"mb-0 base-body-text job-title mt-5 text-center"},[_vm._v(" "+_vm._s(_vm.job.job_title)+" "),(
            _vm.show_industry &&
            _vm.job &&
            _vm.job.sector &&
            _vm.job.sector.toLowerCase() !== 'nan'
          )?_c('span',[_vm._v("- "+_vm._s(_vm.job.sector))]):(
            _vm.show_industry &&
            _vm.job &&
            _vm.job.industry &&
            _vm.job.industry.toLowerCase() !== 'nan'
          )?_c('span',[_vm._v("- "+_vm._s(_vm.job.industry)+" ")]):_vm._e()])],1),_c('div',{staticClass:"card-footer"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }